import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Timer from "../../components/Timer";
import { MoveRight } from "lucide-react";
import ProductSlider from "../../components/ProductSlider";
import { useDispatch, useSelector } from "react-redux";
import SkeletonCarousel from "../../components/SkeletonCarousel";
import { get_limitedtimeofferproducts } from "../../store/reducers/HomeReducer";


const OfferPage = () => {
  const { loading, limited_time_offer_products } = useSelector(
    (state) => state.home
  );
  const dispatch = useDispatch();

  useEffect(()=> {
    dispatch(get_limitedtimeofferproducts({ page: 1, size: 15 }));
  },[dispatch])

  return (
    <div className="container mx-auto md:py-8 p-1">
      <div className="flex justify-between items-center mb-4 px-2">
        <div className="flex items-center flex-col md:flex-row gap-2">
          <h2 className="text-lg md:text-2xl font-semibold">
            Limited Time Offer
          </h2>
          <Timer />
        </div>
        <Link
          to={`/products?specialCategory=${encodeURIComponent(
            "Limited Time Offer"
          ).trim()}`}
          className="text-green-600 flex items-center hover:text-green-700 transition-all text-[14px] md:text-[18px] font-normal"
        >
          View All{" "}
          <span className="ml-1">
            <MoveRight />
          </span>
        </Link>
      </div>
      {!loading && <ProductSlider products={limited_time_offer_products} />}
      {loading && <SkeletonCarousel full={true} />}
      {!loading && limited_time_offer_products.length === 0 && (
        <h4 className="my-10 text-lg font-bold p-3">No Products To Show!</h4>
      )}
    </div>
  );
};

export default OfferPage;
