import React from "react";
import Hero from "./Hero";
import ShopByCategory from "./ShopByCategory";
import TopSellingProducts from "./TopSellingProducts";
import NewProducts from "./NewProducts";
import DownloadAppBanner from "./DownloadAppBanner";
import MeatAndFish from "./MeatAndFish";
import LimitedTimeOffer from "./LimitedTimeOffer";
import RiceAndDal from "./RiceAndDal";
import SpecialDiscount from "./SpecialDiscount";
import DailyProducts from "./DailyProducts";
import FrozelFoods from "./ForzenFoods";
import FrozenFoodSpecialDiscount from "./FrozenFoodSpecialDiscount";
import GetDiscountPage from "./GetDiscountPage";
import OfferPage from "./OfferPage";
import ShopAndEarn from "./ShopAndEarn";
import DiscountStacking from "./DiscountStacking";

const Home = () => {
  return (
    <div className="bg-white overflow-x-hidden max-w-full p-2">
      <Hero />
      <ShopByCategory />
      <TopSellingProducts />
      <DownloadAppBanner />
      <MeatAndFish />
      <div className="container grid md:grid-cols-2 grid-cols-1 md:gap-7">
        <NewProducts />
        <LimitedTimeOffer />
      </div>
      <div className="container grid md:grid-cols-2 grid-cols-1 md:gap-7">
        <RiceAndDal />
        <SpecialDiscount />
      </div>
      
      {/* Local Products */}
      <DailyProducts />

      <div className="container grid md:grid-cols-2 grid-cols-1 md:gap-7">
        <FrozelFoods />
        <FrozenFoodSpecialDiscount />
      </div>

      <GetDiscountPage />
      <OfferPage />
      <DiscountStacking />
      <ShopAndEarn />
    </div>
  );
};

export default Home;