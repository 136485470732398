import React, { useState } from "react";
import Menu from "@mui/material/Menu";

import { FaBars, FaShoppingBag } from "react-icons/fa";

import { BsFillGrid1X2Fill } from "react-icons/bs";
import { MdOutlineLoop, MdLogout } from "react-icons/md";
import { FaRegHeart } from "react-icons/fa";
import { FaGear } from "react-icons/fa6";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { get_cart_quotation, removeCartItems } from "../../store/reducers/CartReducer";
import { useDispatch } from "react-redux";

const NavigationMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate()
  const dispatch = useDispatch()


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const location = useLocation();
  const pathname = location.pathname;

  const handleLogout = async () => {
    handleClose();
    localStorage.removeItem('userId')
    localStorage.removeItem('userInfo')
    localStorage.removeItem('userToken')
    localStorage.removeItem('userSecret')
    localStorage.removeItem('cart_length')
    navigate('/')
    dispatch(removeCartItems())
    dispatch(get_cart_quotation())
  };

  const links = [
    {
      title: "Profile",
      url: "/account/profile",
      icon: <BsFillGrid1X2Fill />,
    },
    {
      title: "My Orders",
      url: "/account/myorders",
      icon: <MdOutlineLoop />,
    },
    {
      title: "My Wishlists",
      url: "/account/mywishlists",
      icon: <FaRegHeart />,
    },
    {
      title: "My Cart",
      url: "/account/mycart",
      icon: <FaShoppingBag />,
    },
    // {
    //   title: "Setting",
    //   url: "/account/setting",
    //   icon: <FaGear />,
    // },
  ];

  return (
    <div>
      <button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className="text-2xl"
      >
        <FaBars />
      </button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        className="mt-5 "
      >
        <div className="min-w-[250px] rounded-lg shadow-sm flex flex-col gap-3 w-full bg-white">
          <h2 className="text-lg font-semibold px-5">Manage My Account</h2>
          <div>
            {links.map((item, i) => (
              <Link
                to={item.url}
                key={i}
                className={`flex px-5 py-3 items-center gap-3 text-neutral-500 font-light group hover:bg-ourPrimary/30 hover:text-black ${
                  pathname === item.url &&
                  "border-r-4 border-r-green-600 text-[#000] font-medium"
                }`}
                onClick={handleClose}
              >
                <span className="text-lg">{item.icon}</span>
                <h3>{item.title}</h3>
              </Link>
            ))}
            <button
              onClick={handleLogout}
              className={`flex px-5 py-3 items-center gap-3 font-medium text-neutral-500 hover:bg-ourPrimary/30 hover:text-black w-full `}
            >
              <span className="text-lg">
                <MdLogout />
              </span>
              <h3>Logout</h3>
            </button>
          </div>
        </div>
      </Menu>
    </div>
  );
};

export default NavigationMenu;
