import HomeReducer from "./reducers/HomeReducer"
import AuthReducer from "./reducers/AuthReducer"
import CartReducer from "./reducers/CartReducer"
// import OrderReducer from "./reducers/OrderReducer"
// import DashboardReducer from "./reducers/DashboardReducer"
// import ChatReducer from "./reducers/ChatReducer"

const rootReducer = {
    home:HomeReducer,
    auth:AuthReducer,
    cart:CartReducer,
    // order:OrderReducer,
    // dashboard:DashboardReducer,
    // chat:ChatReducer,
}

export default rootReducer