import React from "react";
import ProductCard from "../components/ProductCard";
import { Swiper, SwiperSlide } from "swiper/react";


import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";


import { Navigation, Autoplay, Pagination } from "swiper/modules";


const ProductSlider = ({ title, products }) => {
  return (
    <div className="">
      <div className="flex">
        <h2 className="text-2xl font-semibold px-2 border-l-4 border-l-ourPrimary">
          {title}
        </h2>
      </div>
      <Swiper
        slidesPerView={5} // Default number of slides per view
        spaceBetween={20} // Space between slides
        pagination={{ clickable: true }} // Enable clickable pagination buttons
        autoplay={{
          delay: 2000, // 3 seconds delay
          disableOnInteraction: false, // Continue autoplay after user interactions
        }}
        loop={true}
        modules={[Autoplay, Navigation, Pagination]} // Include the necessary modules
        className="mySwiper py-10"
        breakpoints={{
          1224: {
            slidesPerView: 5, // 3 slides on large screens
          },
          640: {
            slidesPerView: 3, // 1 slide on small screens
          },
          0: {
            slidesPerView: 2, // 1 slide on small screens
          },
        }}
      >
        {products.map((product, index) => (
          <SwiperSlide key={index} className="pb-10 pl-1 h-full">
            <ProductCard product={product} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ProductSlider;
