import { HomeIcon } from "lucide-react";
import React, { useState } from "react";
import { FaAngleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import AccountNav from "../../components/Shared/AccountNav/AccountNav";
import NavigationMenu from "./NavigationMenu";

const Settings = () => {
  return (
    <div>
      <div className="relative overflow-hidden -mt-1 bg-black">
        <div className="absolute w-full flex items-center justify-center">
          <img
            src="/assets/productbanner.jpeg"
            alt="Checkout"
            className="brightness-50 mt-[10] md:mt-[-50%] absolute border w-full"
          />
        </div>
        <div className="py-6 text-white relative container font-light px-3">
          <div className="flex items-center gap-2">
            <Link to={"/"}>
              <HomeIcon />
            </Link>
            <FaAngleRight />
            <p>Account</p>
            <FaAngleRight />
            <p className="text-yellow-500">Profile</p>
          </div>
        </div>
      </div>

      <div className="container flex items-start gap-10 h-full w-full bg-white px-3 md:px-8">
        <div className="hidden lg:block max-w-[300px] w-full">
          <AccountNav />
        </div>

        <div className="rounded-lg w-full my-5">
          <div className="p-4 block md:hidden sticky top-0 bg-white z-20">
            <div className="border px-2 py-3 rounded-lg flex items-center justify-between">
              <h3 className="font-semibold">Manage My Account</h3>
              <NavigationMenu />
            </div>
          </div>

          {/* orders */}
          <div className="mt-6 flex flex-col gap-5">
            hellow
          </div>
        </div>
      </div>
    </div>
  )
}

export default Settings
