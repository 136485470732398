import React from "react";
import { FaApple, FaGooglePlay } from "react-icons/fa6";

const DownloadAppBanner = () => {
  return (
    <div className="p-1">
      <div className="mb-5 bg-neutral-950 text-white rounded-xl px-4 py-8 container flex items-center justify-center">
        <div className="flex items-center justify-evenly flex-col md:flex-row gap-5 w-full">
          <h3 className="text-xl font-semibold">Download Our Mobile App</h3>
          <div className="flex  gap-3">
            <div className="bg-slate-100 text-neutral-950 p-2 rounded-lg flex items-center gap-2">
              <FaApple className="text-4xl" />
              <div className="flex flex-col">
                <p className="text-xs">Download on</p>
                <p className="md:text-lg text-sm">App store</p>
              </div>
            </div>
            <div className="bg-slate-100 text-neutral-950 p-2 rounded-lg flex items-center gap-2">
              <FaGooglePlay className="text-4xl" />
              <div className="flex flex-col">
                <p className="text-xs">Download on</p>
                <p className="md:text-lg text-sm">Play store</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadAppBanner;
