import { useEffect, useState } from "react";
import { EyeIcon, EyeOffIcon, LockIcon, CheckCircleIcon } from "lucide-react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  messageClear,
  update_password,
} from "../../store/reducers/AuthReducer";
import toast from "react-hot-toast";

export default function UpdatePassword() {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [logoutAllSessions, setLogoutAllSessions] = useState(false);
  const [searchParams] = useSearchParams();

  const key = searchParams.get("key");
  const { loader, successMessage, errorMessage } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
      navigate("/signin");
      dispatch(messageClear());
      setSuccess(true)
    } else if (errorMessage) {
      toast.error(errorMessage);
      dispatch(messageClear());
    }
  }, [dispatch, successMessage, errorMessage, navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (newPassword.length < 8) {
      setError("Password must be at least 8 characters long");
      return;
    }

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    dispatch(
      update_password({
        new_password: newPassword,
        confirm_password: confirmPassword,
        logout_all_sessions: logoutAllSessions ? 1 : 0,
        key: key,
      })
    );
  };

  if (!key) {
    return (
      <div className="flex items-center justify-center flex-col gap-4 py-5">
        <p>Please check you mail first!</p>
        <Link
          to={"/forgot-password"}
          className="px-5 py-2 rounded-lg bg-ourPrimary hover:bg-ourPrimary-foreground text-white transition-all"
        >
          Reset Password
        </Link>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center bg-white p-4">
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div
          className="absolute left-0 top-0 w-full h-full opacity-[0.03]"
          style={{
            backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%239C92AC' fill-opacity='0.4'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
          }}
        ></div>
      </div>
      <div className="max-w-md w-full space-y-8 bg-white p-10 rounded-2xl shadow-[0_0_60px_-15px_rgba(0,0,0,0.1)] transform transition-all duration-300 hover:shadow-[0_0_70px_-15px_rgba(0,0,0,0.2)] relative z-10">
        <div className="text-center">
          <div className="mx-auto bg-gradient-to-r from-blue-500 to-teal-400 rounded-full p-2 w-16 h-16 flex items-center justify-center mb-4 shadow-lg">
            <LockIcon className="h-8 w-8 text-white" />
          </div>
          <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
            Update Your Password
          </h2>
          <p className="mt-2 text-sm text-gray-600">
            Choose a strong password to keep your account secure
          </p>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <div className="rounded-md space-y-3">
            <div className="relative">
              <label htmlFor="new-password" className="sr-only">
                New Password
              </label>
              <input
                id="new-password"
                name="newPassword"
                type={showPassword ? "text" : "password"}
                required
                className="px-5 w-full bg-transparent py-2 border outline-none rounded-lg focus:border-ourPrimary transition-all"
                placeholder="New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
            <div className="relative">
              <label htmlFor="confirm-password" className="sr-only">
                Confirm New Password
              </label>
              <input
                id="confirm-password"
                name="confirmPassword"
                type={showPassword ? "text" : "password"}
                required
                className="px-5 w-full bg-transparent py-2 border outline-none rounded-lg focus:border-ourPrimary transition-all"
                placeholder="Confirm New Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <EyeOffIcon className="h-5 w-5 text-gray-400 hover:text-gray-600 transition-colors duration-200" />
                ) : (
                  <EyeIcon className="h-5 w-5 text-gray-400 hover:text-gray-600 transition-colors duration-200" />
                )}
              </button>
            </div>
          </div>

          <div className="flex items-center">
            <input
              id="logout-all-sessions"
              name="logoutAllSessions"
              type="checkbox"
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
              checked={logoutAllSessions}
              onChange={(e) => setLogoutAllSessions(e.target.checked)}
            />
            <label htmlFor="logout-all-sessions" className="ml-2 block text-sm text-gray-900">
              Logout all sessions
            </label>
          </div>

          {error && (
            <p className="text-red-500 text-sm mt-2 animate-fade-in-down flex items-center">
              <span className="bg-red-100 rounded-full p-1 mr-2">
                <LockIcon className="h-4 w-4 text-red-500" />
              </span>
              {error}
            </p>
          )}

          {success && (
            <p className="text-green-500 text-sm mt-2 animate-fade-in-down flex items-center">
              <span className="bg-green-100 rounded-full p-1 mr-2">
                <CheckCircleIcon className="h-4 w-4 text-green-500" />
              </span>
              Password updated successfully!
            </p>
          )}

          <div>
            <button
              type="submit"
              disabled={loader}
              className={`group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gradient-to-r from-blue-500 to-teal-400 hover:from-blue-600 hover:to-teal-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transform transition-all duration-150 hover:scale-[1.02] active:scale-[0.98] shadow-md hover:shadow-lg ${loader && "pointer-events-none opacity-70"}`}
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <LockIcon
                  className="h-5 w-5 text-blue-200 group-hover:text-blue-100"
                  aria-hidden="true"
                />
              </span>
              {loader ? "Updating..." : "Update Password"}
            </button>
          </div>
        </form>
        <div className="mt-6">
          <h3 className="text-sm font-medium text-gray-700 mb-2">
            Password requirements:
          </h3>
          <ul className="text-xs text-gray-500 list-disc list-inside space-y-1">
            <li>At least 8 characters long</li>
            <li>Contains at least one uppercase letter</li>
            <li>Contains at least one number</li>
            <li>Contains at least one special character</li>
          </ul>
        </div>
      </div>
    </div>
  );
}