import React, { useState, useEffect } from "react";

const Timer = () => {
  const calculateTimeLeft = () => {
    let now = new Date();
    let nextMidnight = new Date();
    nextMidnight.setHours(24, 0, 0, 0); // Set to 12 AM next day
    let difference = nextMidnight - now;

    let timeLeft = {
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div>
      <div className="flex items-center gap-1 text-lg font-semibold">
        <div className="text-white bg-ourPrimary h-8 w-8 flex items-center justify-center rounded">{timeLeft.hours}</div> : <div className="text-white bg-ourPrimary h-8 w-8 flex items-center justify-center rounded">{timeLeft.minutes}</div> :
        <div className="text-white bg-ourPrimary h-8 w-8 flex items-center justify-center rounded">{timeLeft.seconds}</div>
      </div>
    </div>
  );
};

export default Timer;
