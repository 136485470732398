import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";
import { FaRegHeart } from "react-icons/fa";
import { MoveRight } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import {
  get_limitedtimeofferproducts,
  messageClear,
} from "../../store/reducers/HomeReducer";
import {
  addToWishlist,
  clearMessages,
  generateSessionId,
  updateGuestUserCart,
  updateRegisteredUserCart,
} from "../../store/reducers/CartReducer";
import toast from "react-hot-toast";

const LimitedTimeOffer = () => {
  const [userToken] = useState(localStorage.getItem("userSecret"));
  const [sessionId, setSessionId] = useState(
    localStorage.getItem("session_id")
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { limited_time_offer_products, loading } = useSelector(
    (state) => state.home
  );
  const {
    loading: loader,
    successMessage,
    errorMessage,
  } = useSelector((state) => state.cart);
  useEffect(() => {
    const dis = dispatch(get_limitedtimeofferproducts({ page: 1, size: 15 }));
  }, [dispatch]);

  useEffect(() => {
    const token = localStorage.getItem("session_id");
    setSessionId(token);
  }, []);

  useEffect(() => {
    if (successMessage) {
      // toast.success(successMessage);
      dispatch(clearMessages());
    }
    if (errorMessage) {
      // toast.error(errorMessage);
      dispatch(clearMessages());
    }
  }, [dispatch, successMessage, errorMessage]);

  const handleAddToCart = async (id) => {
    const addToCart = (sessionId) => {
      const actionPayload = {
        itemCode: id,
        qty: 1,
        withItems: 0,
        sessionId,
      };

      toast.success("Added to Cart")

      if (userToken) {
        dispatch(updateRegisteredUserCart(actionPayload));
      } else {
        dispatch(updateGuestUserCart(actionPayload));
      }
    };

    if (!userToken && !sessionId) {
      const action = dispatch(generateSessionId());
      if (action?.payload?.message) {
        addToCart(sessionId);
      }
    } else {
      addToCart(sessionId);
    }
  };

  const handleWishlist = async (id) => {
    if (!userToken) {
      navigate("/signin");
    } else {
      dispatch(addToWishlist({ itemCode: id }));
    }
  };

  return (
    <div className="h-full flex flex-col justify-between p-3 md:pt-6">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg md:text-2xl font-semibold">
          Limited Time Offer
        </h2>
        <Link
          to={`/products?specialCategory=${encodeURIComponent(
            "Limited Time Offer"
          ).trim()}`}
          className="text-green-600 flex items-center hover:text-green-700 transition-all text-[14px] md:text-[18px] font-normal"
        >
          View All{" "}
          <span className="ml-1">
            <MoveRight />
          </span>
        </Link>
      </div>
      <div
        className="bg-[#f6f7e8] rounded-xl h-full md:my-8"
        style={{ display: loading ? "none" : "block" }}
      >
        <Swiper
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Autoplay]}
          className="mySwiper h-full"
          autoplay={{ delay: 3000 }}
          slidesPerView={1}
        >
          {limited_time_offer_products &&
            limited_time_offer_products.slice(0, 5)?.map((item, i) => (
              <SwiperSlide
                key={i}
                className="h-full p-5 md:p-10 max-w-full relative py-10"
              >
                <div className="grid grid-cols-2 h-full gap-4">
                  <div className="flex flex-col justify-center gap-2 h-full w-full">
                    <h4 className="text-xl md:text-2xl lg:text-3xl font-semibold">
                      Hurry Up!
                    </h4>
                    <p className="text-sm md:text-base">{item.offer_ends}</p>
                    <div className="h-[70px]"></div>
                    <div className="mt-2 flex items-center justify-between text-neutral-600">
                      <div className="flex text-lg md:text-2xl font-bold">
                        {/* <FaBangladeshiTakaSign /> */}
                        <span>{item.formatted_price}</span>
                      </div>
                      <div className="flex text-neutral-300">
                        {/* <FaBangladeshiTakaSign /> */}
                        <span className="line-through">
                          {item.formatted_mrp}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center justify-between mt-2 text-neutral-600">
                      <button
                        onClick={() => handleWishlist(item.item_code)}
                        className="border border-ourPrimary rounded-full p-2 font-bold hover:bg-ourPrimary hover:text-white transition-all"
                      >
                        <FaRegHeart className="text-xl" />
                      </button>
                      <button
                        onClick={() => handleAddToCart(item.item_code)}
                        className="border border-ourPrimary rounded-full px-2 text-xs md:text-sm md:px-5 py-2 font-bold hover:bg-ourPrimary hover:text-white transition-all"
                      >
                        {loader ? "Adding..." : "Add to Cart"}
                      </button>
                    </div>
                  </div>
                  <div className="flex items-center justify-center">
                    <img
                      src={`${process.env.REACT_APP_API_URL}${item.website_image}`}
                      alt="product"
                      className="max-w-full h-auto mix-blend-multiply"
                    />
                  </div>
                </div>
              </SwiperSlide>
            ))}
          {!loading && limited_time_offer_products.length === 0 && (
            <SwiperSlide className="flex items-center justify-center w-full">
              <h4 className="my-10 text-lg font-bold flex items-center justify-center">
                No Products To Show!
              </h4>
            </SwiperSlide>
          )}
        </Swiper>
      </div>
      {loading && (
        <div className="h-full w-full bg-neutral-200 animate-pulse rounded-lg max-h-[500px]"></div>
      )}
    </div>
  );
};

export default LimitedTimeOffer;
