import { ArrowLeft } from "lucide-react";
import React from "react";
import NavBar from "../components/Shared/NavBar/NavBar";


const ErrorPage = ({noNav=true}) => {
  return (
    <div className="bg-[#F0F0F0] h-screen">
      {noNav && <NavBar />}
      <div className="flex items-center justify-center flex-col min-h-[60vh] bg-[#F0F0F0]">
        <h1 className="text-6xl font-bold">Oops!</h1>
        <img
          src="https://rgicdn.s3.ap-south-1.amazonaws.com/ghrcenagpur/images/gif/404.gif"
          alt="Not Found"
        />
        <p>
          <span className="font-bold text-lg">Page Not Found!</span>
        </p>
        <button
          onClick={() => window.history.back()}
          className="text-white bg-ourPrimary px-5 py-2 rounded-full mt-1 hover:bg-ourPrimary-foreground transition-all flex items-center gap-2 uppercase font-bold"
        >
          <ArrowLeft />
          Go Back
        </button>
      </div>
    </div>
  );
};

export default ErrorPage;
