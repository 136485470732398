import React, { useState, useRef, useEffect } from 'react'
import { ChevronDown, Search, Check } from 'lucide-react'

export function CustomSearchableSelect({ options, placeholder = "Select an option", value, setValue, disabled = false }) {
  const [isOpen, setIsOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const wrapperRef = useRef(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef])

  const filteredOptions = options.filter(option =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase()) ||
    option.group.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const groupedOptions = filteredOptions.reduce((acc, option) => {
    if (!acc[option.group]) {
      acc[option.group] = []
    }
    acc[option.group].push(option)
    return acc
  }, {})

  const handleSelectOption = (option) => {
    setValue(option.value)
    setIsOpen(false)
    setSearchTerm('')
  }

  const selectedOption = options.find(option => option.value === value)

  return (
    <div className="relative mt-2" ref={wrapperRef}>
      <button
        className={`w-full p-2 text-left bg-white border border-gray-300 rounded-md focus:outline-none${
          disabled ? 'opacity-50 cursor-not-allowed' : ''
        }`}
        onClick={() => !disabled && setIsOpen(!isOpen)}
        disabled={disabled}
      >
        {selectedOption ? `${selectedOption.label} (${selectedOption.group})` : placeholder}
        <ChevronDown className="absolute right-2 top-3 w-5 h-5 text-gray-400" />
      </button>
      {isOpen && !disabled && (
        <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg">
          <div className="p-2 border-b border-gray-300">
            <div className="relative">
              <Search className="absolute left-2 top-2.5 w-5 h-5 text-gray-400" />
              <input
                type="text"
                className="w-full p-2 pl-9 text-sm border border-gray-300 bg-transparent rounded-md focus:outline-none"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
          <div className="max-h-60 overflow-auto">
            {Object.entries(groupedOptions).map(([group, options]) => (
              <div key={group}>
                <div className="px-3 py-2 font-semibold bg-gray-100">{group}</div>
                <ul>
                  {options.map((option) => (
                    <li
                      key={option.value}
                      className={`px-3 py-2 cursor-pointer hover:bg-blue-50 flex items-center ${
                        value === option.value ? 'bg-blue-100' : ''
                      }`}
                      onClick={() => handleSelectOption(option)}
                    >
                      <span className="flex-grow">{option.label}</span>
                      {value === option.value && (
                        <Check className="w-5 h-5 text-blue-500" />
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
            {Object.keys(groupedOptions).length === 0 && (
              <div className="px-3 py-2 text-gray-500">No options found</div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

