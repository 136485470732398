import { createBrowserRouter } from "react-router-dom";
import MainLayout from "../Layouts/MainLayout";
import Home from "../Pages/Home/Home";
import { lazy, Suspense } from "react";
import ErrorPage from "../Pages/ErrorPage";
import LoadingPage from "../Pages/LoadingPage";
import Checkout from "../Pages/Checkout/Checkout";
import Profile from "../Pages/Account/Profile";
import Blog from "../Pages/Blog/Blog";
import SingleBlogPage from "../Pages/Blog/SingleBlogPage";
import Orders from "../Pages/Account/Orders";
import Wishlist from "../Pages/Account/Wishlist";
import MyCart from "../Pages/Account/MyCart";
import Products from "../Pages/products/Products";
import Settings from "../Pages/Account/Settings";
import ShopAndEarn from "../Pages/ShopandEarn/ShopAndEarn";
import DiscountStacking from "../Pages/DiscountStacking/DiscountStacking";
import OrderDetails from "../Pages/OrderDetails";
import Payment from "../Pages/Checkout/Payment";
import PaymentSuccess from "../components/PaymentSuccess";
import AreaCoverage from "../Pages/products/AreaCoverage";
import UpdatePassword from "../Pages/signin/UpdatePassword";
import Kenotoday from "../Pages/Kenooday/Kenotoday";

const SignIn = lazy(() => import("../Pages/signin/SignIn"));
const SignUp = lazy(() => import("../Pages/signin/SignUp"));
const ForgotPassword = lazy(() => import("../Pages/signin/ForgotPassword"));
const Product = lazy(() => import("../Pages/Product/Product"));

export const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout></MainLayout>,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Home></Home>,
      },
      {
        path: "/signin",
        element: (
          <Suspense fallback={<LoadingPage />}>
            <SignIn />
          </Suspense>
        ),
      },
      {
        path: "/signup",
        element: (
          <Suspense fallback={<LoadingPage />}>
            <SignUp />
          </Suspense>
        ),
      },
      {
        path: "/checkout",
        element: (
          <Suspense fallback={<LoadingPage />}>
            <Checkout />
          </Suspense>
        ),
      },
      {
        path: "/blog",
        element: (
          <Suspense fallback={<LoadingPage />}>
            <Blog />
          </Suspense>
        ),
      },
      {
        path: "/blog/:fsdfsd",
        element: (
          <Suspense fallback={<LoadingPage />}>
            <SingleBlogPage />
          </Suspense>
        ),
      },
      {
        path: "/product/:productid",
        element: (
          <Suspense fallback={<LoadingPage />}>
            <Product />
          </Suspense>
        ),
      },
      {
        path: "/account/profile",
        element: (
          <Suspense fallback={<LoadingPage />}>
            <Profile />
          </Suspense>
        ),
      },
      {
        path: "/account/myorders",
        element: (
          <Suspense fallback={<LoadingPage />}>
            <Orders />
          </Suspense>
        ),
      },
      {
        path: "/account/mywishlists",
        element: (
          <Suspense fallback={<LoadingPage />}>
            <Wishlist />
          </Suspense>
        ),
      },
      {
        path: "/account/mycart",
        element: (
          <Suspense fallback={<LoadingPage />}>
            <MyCart />
          </Suspense>
        ),
      },
      {
        path: "/account/setting",
        element: (
          <Suspense fallback={<LoadingPage />}>
            <Settings />
          </Suspense>
        ),
      },
      {
        path: "/orderdetails/:orderid",
        element: (
          <Suspense fallback={<LoadingPage />}>
            <OrderDetails />
          </Suspense>
        ),
      },

      {
        path: "/products",
        element: (
          <Suspense fallback={<LoadingPage />}>
            <Products />
          </Suspense>
        ),
      },
      {
        path: "/shopandearn",
        element: (
          <Suspense fallback={<LoadingPage />}>
            <ShopAndEarn />
          </Suspense>
        ),
      },
      {
        path: "/discountstacking",
        element: (
          <Suspense fallback={<LoadingPage />}>
            <DiscountStacking />
          </Suspense>
        ),
      },
      {
        path: "/forgot-password",
        element: (
          <Suspense fallback={<LoadingPage />}>
            <ForgotPassword />
          </Suspense>
        ),
      },
      {
        path: "/payment",
        element: (
          <Suspense fallback={<LoadingPage />}>
            <Payment />
          </Suspense>
        ),
      },
      {
        path: "/payment-success",
        element: (
          <Suspense fallback={<LoadingPage />}>
            <PaymentSuccess />
          </Suspense>
        ),
      },
      {
        path: "/area-coverage",
        element: (
          <Suspense fallback={<LoadingPage />}>
            <AreaCoverage />
          </Suspense>
        ),
      },
      {
        path: "/update-password",
        element: (
          <Suspense fallback={<LoadingPage />}>
            <UpdatePassword />
          </Suspense>
        ),
      },
      {
        path: "/kenotoday",
        element: (
          <Suspense fallback={<LoadingPage />}>
            <Kenotoday />
          </Suspense>
        ),
      },
    ],
  },
]);

// testing new branch
