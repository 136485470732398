import { FilePenLine, HomeIcon, Package, Store, TimerIcon } from "lucide-react";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaAngleRight, FaCalendar, FaDollarSign } from "react-icons/fa6";
import toast from "react-hot-toast";
import { RadioGroup, RadioGroupItem } from "../../components/ui/RadioGroup";
import { useDispatch, useSelector } from "react-redux";
import {
  applyCouponCode,
  clearMessages,
  get_cart_quotation,
  get_pickup_stores,
  handelInfoSave,
  update_cart,
} from "../../store/reducers/CartReducer";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/Select";

import { countries, usStates } from "../../utils/commons";
import {
  get_delivery_slot,
  get_shipping_lists,
  get_userinfo,
} from "../../store/reducers/HomeReducer";
import { MapPinIcon, UserIcon, PhoneIcon, GlobeIcon } from "lucide-react";
import { CustomSearchableSelect } from "./CitySelection";

const paymentMethods = [
  {
    name: "Card Payment",
    imgSrc:
      "https://upload.wikimedia.org/wikipedia/commons/0/04/Mastercard-logo.png",
    imgClass: "w-6",
    value: "card",
  },
  {
    name: "Visa Payment",
    imgSrc:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/Old_Visa_Logo.svg/2560px-Old_Visa_Logo.svg.png",
    imgClass: "w-6",
    value: "visa",
  },
  {
    name: "Visa Payment",
    imgSrc: "https://logowik.com/content/uploads/images/amex-card1708.jpg",
    imgClass: "w-10",
    value: "visa",
  },
  {
    name: "Google Pay",
    imgSrc: "https://cdn-icons-png.flaticon.com/512/6124/6124998.png",
    imgClass: "h-8",
    value: "google_pay",
  },
  {
    name: "Apple Pay",
    imgSrc:
      "https://seeklogo.com/images/A/apple-pay-logo-F68C9AC252-seeklogo.com.png",
    imgClass: "w-6",
    value: "apple_pay",
  },
  // {
  //   name: "PayPal",
  //   imgSrc:
  //     "https://upload.wikimedia.org/wikipedia/commons/thumb/3/39/PayPal_logo.svg/1024px-PayPal_logo.svg.png",
  //   imgClass: "h-5 my-2",
  //   value: "paypal",
  // },
];

const BASE_URL = process.env.REACT_APP_API_URL;

const Checkout = () => {
  const [name, setName] = useState("");
  const [validate, setValidate] = useState(false);
  const [number, setNumber] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [selectedState, setSelectedState] = useState("New York");
  const [shippingCity, setShippingCity] = useState("");
  const [shippingZip, setShippingZip] = useState("");
  const [shippingSelectedState, setShippingSelectedState] =
    useState("New York");
  const [country, setCountry] = useState("United States");
  const [shippingCountry, setShippingCountry] = useState("United States");
  const [curpon, setCurpon] = useState("");
  const [curponDiscount, setCurponDiscount] = useState("");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("card");
  const [deliveryDate, setDeliveryDate] = useState();
  const [billingAddressOne, setBillingAddressOne] = useState("");
  const [billingAddressTwo, setBillingAddressTwo] = useState("");
  const [streetAddressOne, setStreetAddressOne] = useState("");
  const [streetAddressTwo, setStreetAddressTwo] = useState("");
  const [isSame, setIsSame] = useState(false);
  const [selectedStore, setSelectedStore] = useState("");
  const [storeInfo, setStoreInfo] = useState();

  const [isLoading, setIsLoading] = useState(false);

  const [sessionId, setSessionId] = useState(
    localStorage.getItem("session_id")
  );
  const [deliveryMethod, setDeliveryMethod] = useState("Home Delivery");
  const [deliveryType, setDeliveryType] = useState("Standard Delivery");
  const [deliverySlot, setDeliverySlot] = useState("");
  const [isValidZip, setIsValidZip] = useState(false);

  const {
    cartItems,
    coupon,
    loading: loader,
    successMessage,
    errorMessage,
    infosave,
    stores,
  } = useSelector((state) => state.cart);
  const {
    user_info,
    delivery_slots,
    neibourhoods: neighborhoodData,
  } = useSelector((state) => state.home);
  const dispatch = useDispatch();

  const [userInfo, setUserInfo] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (user_info) {
      if (user_info.first_name) {
        setName(user_info?.first_name + " " + user_info?.last_name || "");
      }
      setNumber(user_info?.mobile_no || "");
      setBillingAddressOne(user_info?.address?.address_line1 || "");
      setBillingAddressTwo(user_info?.address?.address_line2 || "");
      setCity(user_info?.address?.city || "");
      setSelectedState(user_info?.address?.state || "New York");
      setCountry(user_info?.address?.country || "United States");
      setZipCode(user_info?.address?.pincode || "");
    }
  }, [user_info]);

  console.log(neighborhoodData);
  useEffect(() => {
    dispatch(get_shipping_lists());
  }, [dispatch]);

  useEffect(() => {
    dispatch(get_userinfo());
    dispatch(get_pickup_stores());
  }, [dispatch]);

  useEffect(() => {
    dispatch(get_delivery_slot({ type: deliveryType }));
  }, [dispatch, deliveryType]);

  useEffect(() => {
    if (cartItems?.cart?.items?.length === 0) {
      navigate("/");
    }
  }, [navigate, cartItems?.cart?.items?.length]);

  useEffect(() => {
    dispatch(get_cart_quotation({ sessionId }));
  }, [sessionId, successMessage, dispatch]);

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
      dispatch(clearMessages());
    } else if (errorMessage) {
      toast.dismiss();
      toast.error(errorMessage);
      dispatch(clearMessages());
    }
  }, [successMessage, errorMessage, dispatch]);

  useEffect(() => {
    const userInfo = localStorage.getItem("userInfo");
    setUserInfo(userInfo && JSON.parse(userInfo)[0]);
  }, []);

  useEffect(() => {
    if (userInfo) {
      setName(userInfo.first_name + " " + userInfo.last_name);
      setNumber(userInfo.mobile_no);
    }
  }, [userInfo]);

  //function
  const subTotalPrice = cartItems?.cart?.items
    ?.filter((item) => item.amount)
    ?.reduce((acc, item) => acc + item.amount, 0);

  const totalTax =
    cartItems?.cart?.taxes
      ?.filter((tax) => tax.tax_amount)
      ?.reduce((acc, tax) => acc + tax.tax_amount, 0) || 0;

  const totalPrice =
    cartItems?.cart?.items
      ?.filter((item) => item.amount)
      ?.reduce((acc, item) => acc + item.amount, 0) + totalTax;

  const discountPercentage = parseFloat(curponDiscount) / 100;
  const discountedPrice = totalPrice * (1 - discountPercentage);

  const handlePlaceOrder = async () => {
    setValidate(true);
    if (
      name &&
      number &&
      billingAddressOne &&
      city &&
      zipCode &&
      selectedState
    ) {
      if (selectedPaymentMethod) {
        try {
          setIsLoading(true);
          const response = await fetch(
            `${BASE_URL}/api/method/keno_store.cart_api.place_order`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${
                  localStorage.getItem("userToken") ??
                  "token 4a0696b9a891928:85fb770201d0794"
                }`,
              },
              body: JSON.stringify({
                session_id: localStorage.getItem("session_id"),
                payment_method: selectedPaymentMethod,
              }),
            }
          );
          const data = await response.json();

          const client_secret = data.data.client_secret;
          setIsLoading(false);
          if (client_secret) {
            navigate(
              `/payment?id=${client_secret}&payment=${selectedPaymentMethod}`
            );
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        toast.error("Select Payment Method");
      }
    } else {
      toast.error("Fill all Fields!");
    }
  };

  const applyCoupon = () => {
    if (curpon) {
      dispatch(
        applyCouponCode({ session_id: sessionId, applied_code: curpon })
      );
    } else {
      toast.error("Add Coupon Code First!");
    }
  };

  function convertTimeFormat(dateString) {
    // Parse the input date string
    const date = new Date(dateString);

    // Define the days of the week
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    // Get the day of the week
    const dayOfWeek = daysOfWeek[date.getDay()];

    // Get the start time (hours, minutes, seconds)
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = "00"; // Assuming the seconds are always 00 if not provided

    // Formatted start time
    const startTime = `${hours}:${minutes}:${seconds}`;

    // Calculate end time (e.g., add 2 hours to the start time)
    const endDate = new Date(date.getTime() + 2 * 60 * 60 * 1000); // Add 2 hours
    const endHours = String(endDate.getHours()).padStart(2, "0");
    const endMinutes = String(endDate.getMinutes()).padStart(2, "0");
    const endTime = `${endHours}:${endMinutes}:${seconds}`;

    // Mockup ID (since it's unclear how to generate this dynamically)
    const id = "00001";

    // Construct the final format
    return `${dayOfWeek}: ${startTime}-${endTime}-${id}`;
  }

  useEffect(() => {
    // Check if stores is an array before filtering
    if (Array.isArray(stores)) {
      const storeDetails = stores.find((store) => store.name == selectedStore);
      // console.log(storeDetails);

      setStoreInfo(storeDetails);
    }
  }, [selectedStore, stores]);

  //!

  const zipRegex = /^\d{5}(-\d{4})?$/;

  // Helper function to validate ZIP Codes
  const cityRegex = /^[a-zA-Z]{2,50}(?:[ '-][a-zA-Z]+)*$/;

  const validateCities = (city, shippingCity) => {
    const isBillingCityValid = cityRegex.test(city);

    if (!isSame) {
      if (!isBillingCityValid) {
        toast.error("Billing city name is required and must be valid.");
        return false;
      }
    } else {
      if (!isBillingCityValid) {
        toast.error("At least one valid city name is required.");
        return false;
      }
    }
    return true;
  };

  const validateZipCodes = (zipCode, shippingZip) => {
    const isBillingZipValid = zipRegex.test(zipCode);
    const isShippingZipValid = zipRegex.test(shippingZip);

    if (!isSame) {
      if (!isBillingZipValid || !isShippingZipValid) {
        toast.error("Both ZIP Codes are required and must be valid.");
        return false;
      }
    } else {
      if (!isBillingZipValid && !isShippingZipValid) {
        toast.error("At least one valid ZIP Code is required.");
        return false;
      }
    }
    return true;
  };

  const validateCityAndZip = (city, zip) => {
    // Flag to track if the city and zip match
    let isCityValid = false;
    let isZipValid = false;

    for (const county in neighborhoodData) {
      const neighborhoods = neighborhoodData[county];
      for (const neighborhood in neighborhoods) {
        const zipCodes = neighborhoods[neighborhood];

        if (neighborhood === city) {
          isCityValid = true; // City exists in the data
          if (zipCodes.includes(zip)) {
            isZipValid = true; // City and ZIP match
          }
        }
      }
    }

    // Toast messages based on validation results
    if (!isCityValid) {
      toast.error("Shipping City is not valid!", {
        style: {
          background: "#333", // Change to your preferred color
          color: "#fff", // Adjust text color if needed
        },
      });
      return false;
    }

    // if (!isZipValid) {
    //   toast.error("The ZIP code does not match the selected city.");
    //   return false;
    // }

    // toast.success("The city and ZIP code are valid!");
    return true;
  };

  const handleSave = async () => {
    setValidate(true);

    // Validate both ZIP Codes first
    // const zipValidationPassed = validateZipCodes(zipCode, shippingZip);
    // if (!zipValidationPassed) return;

    // Validate cities
    const cityValidationPassed = validateCities(city, shippingCity);
    if (!cityValidationPassed) return;

    const validateCityzip = isSame && validateCityAndZip(city, zipCode);

    if (isSame && !validateCityzip) return;

    if(isSame && selectedState !== "New York"){
      toast.error("Shipping state not allowed")
      return
    }

    // Proceed with form validation and dispatch if ZIP Codes and Cities are valid
    if (
      name &&
      number &&
      billingAddressOne &&
      selectedState &&
      city &&
      zipCode &&
      country &&
      deliveryMethod &&
      deliverySlot
    ) {
      if (
        !isSame &&
        (!streetAddressOne ||
          !shippingCity ||
          !shippingSelectedState ||
          !shippingZip ||
          !shippingCountry)
      ) {
        toast.error("Enter Shipping address");
      } else {
        dispatch(
          update_cart({
            session_id: sessionId,
            cart: {
              contact_name: name,
              contact_mobile: number,
              billing_address: {
                address_line1: billingAddressOne,
                address_line2: billingAddressTwo,
                state: selectedState,
                city: city,
                pincode: zipCode,
                country: country,
              },
              shipping_address: {
                address_line1: isSame ? billingAddressOne : streetAddressOne,
                address_line2: isSame ? billingAddressTwo : streetAddressTwo,
                state: isSame ? selectedState : shippingSelectedState,
                city: isSame ? city : shippingCity,
                pincode: isSame ? zipCode : shippingZip,
                country: isSame ? country : shippingCountry,
              },
              delivery_option: {
                delivery_method: deliveryMethod,
                delivery_slot: deliverySlot,
                store: selectedStore,
                store_pickup_time: deliveryDate,
                delivery_type: deliveryType,
              },
            },
          })
        );
      }
    } else {
      toast.error("Please Fill all Inputs");
    }
  };

  function formatString(schedule) {
    // Extract day and times from the input string
    const [day, times] = schedule.split(": ");
    const [startTime, endTime] = times.split("-").slice(0, 2);

    // Map days to indexes (0 = Sunday, 1 = Monday, etc.)
    const dayIndex = {
      Sunday: 0,
      Monday: 1,
      Tuesday: 2,
      Wednesday: 3,
      Thursday: 4,
      Friday: 5,
      Saturday: 6,
    };

    // Get today's date
    const today = new Date();
    const currentDayIndex = today.getDay();

    // Calculate the date for the specified day (if today is Monday, we use today’s date)
    const targetDate = new Date();
    targetDate.setDate(
      today.getDate() + ((dayIndex[day] - currentDayIndex + 7) % 7)
    );

    // Set start and end times
    const [startHour, startMinute] = startTime.split(":");
    targetDate.setHours(parseInt(startHour), parseInt(startMinute), 0);

    // Format date and times to desired format
    const formattedDate = new Intl.DateTimeFormat("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    }).format(targetDate);

    const formattedStartTime = new Intl.DateTimeFormat("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    }).format(new Date(`${formattedDate} ${startTime}`));

    const formattedEndTime = new Intl.DateTimeFormat("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    }).format(new Date(`${formattedDate} ${endTime}`));

    return `${formattedDate} (${formattedStartTime}-${formattedEndTime})`;
  }

  // const neighborhoodData = {
  //   Queens: {
  //     Astoria: ["11102", "11103", "11105", "11106"],
  //     Auburndale: ["11358"],
  //     Bayside: ["11360", "11361", "11364"],
  //     "Belle Harbor": ["11694"],
  //     Bellerose: ["11426"],
  //     Briarwood: ["11435"],
  //     "Broad Channel": ["11693"],
  //     "Cambria Heights": ["11411"],
  //     "College Point": ["11356"],
  //     Corona: ["11368"],
  //     Douglaston: ["11362", "11363"],
  //     "East Elmhurst": ["11369", "11370"],
  //     Elmhurst: ["11373"],
  //     "Far Rockaway": ["11691"],
  //     Flushing: ["11354", "11355", "11367"],
  //     "Forest Hills": ["11375"],
  //     "Fresh Meadows": ["11365", "11366"],
  //     Glendale: ["11385"],
  //     Hollis: ["11423"],
  //     "Howard Beach": ["11414"],
  //     "Jackson Heights": ["11372"],
  //     Jamaica: ["11432", "11433", "11434", "11435", "11436"],
  //     "Jamaica Estates": ["11432"],
  //     "Kew Gardens": ["11415"],
  //     Laurelton: ["11413"],
  //     "Little Neck": ["11362"],
  //     "Long Island City": ["11101", "11109", "11120"],
  //     Maspeth: ["11378"],
  //     "Middle Village": ["11379"],
  //     "Ozone Park": ["11416", "11417"],
  //     "Queens Village": ["11427", "11428", "11429"],
  //     "Rego Park": ["11374"],
  //     "Richmond Hill": ["11418"],
  //     Ridgewood: ["11385"],
  //     "Rockaway Park": ["11694"],
  //     Rosedale: ["11422"],
  //     "South Ozone Park": ["11420"],
  //     "Springfield Gardens": ["11413"],
  //     Sunnyside: ["11104"],
  //     Whitestone: ["11357"],
  //     Woodhaven: ["11421"],
  //     Woodside: ["11377"],
  //   },
  //   "Nassau County": {
  //     Albertson: ["11507"],
  //     "Atlantic Beach": ["11509"],
  //     Baldwin: ["11510"],
  //     "Baldwin Harbor": ["11510"],
  //     "Bay Park": ["11518"],
  //     Bayville: ["11709"],
  //     "Bellerose Terrace": ["11001"],
  //     Bethpage: ["11714"],
  //     Brookville: ["11545"],
  //     "Carle Place": ["11514"],
  //     Cedarhurst: ["11516"],
  //     "Centre Island": ["11771"],
  //     "East Hills": ["11548", "11576"],
  //     "East Meadow": ["11554"],
  //     "East Norwich": ["11732"],
  //     Elmont: ["11003"],
  //     Farmingdale: ["11735"],
  //     "Floral Park": ["11001", "11002"],
  //     "Franklin Square": ["11010"],
  //     Freeport: ["11520"],
  //     "Garden City": ["11530", "11531"],
  //     "Glen Cove": ["11542"],
  //     "Glen Head": ["11545"],
  //     "Glenwood Landing": ["11547"],
  //     "Great Neck": ["11020", "11021", "11023", "11024", "11026"],
  //     "Great Neck Estates": ["11021"],
  //     "Great Neck Plaza": ["11021"],
  //     Greenvale: ["11548"],
  //     Hempstead: ["11550"],
  //     Herricks: ["11507"],
  //     Hewlett: ["11557"],
  //     Hicksville: ["11801"],
  //     Inwood: ["11096"],
  //     "Island Park": ["11558"],
  //     Jericho: ["11753"],
  //     Kensington: ["11021"],
  //     "Kings Point": ["11024"],
  //     "Lake Success": ["11020"],
  //     Levittown: ["11756"],
  //     "Lido Beach": ["11561"],
  //     Lynbrook: ["11563"],
  //     Malverne: ["11565"],
  //     Manhasset: ["11030"],
  //     Massapequa: ["11758"],
  //     "Massapequa Park": ["11762"],
  //     Merrick: ["11566"],
  //     "Mill Neck": ["11765"],
  //     Mineola: ["11501"],
  //     "New Cassel": ["11590"],
  //     "North Bellmore": ["11710"],
  //     "North Hempstead": ["11576", "11577"],
  //     "North Massapequa": ["11758"],
  //     "North Merrick": ["11566"],
  //     Oceanside: ["11572"],
  //     "Old Bethpage": ["11804"],
  //     "Old Brookville": ["11545"],
  //     "Old Westbury": ["11568"],
  //     "Oyster Bay": ["11771"],
  //     Plainview: ["11803"],
  //     "Port Washington": ["11050"],
  //     "Rockville Centre": ["11570"],
  //     Roosevelt: ["11575"],
  //     Roslyn: ["11576"],
  //     "Roslyn Estates": ["11576"],
  //     "Roslyn Heights": ["11577"],
  //     "Saddle Rock": ["11023"],
  //     "Sands Point": ["11050"],
  //     "Sea Cliff": ["11579"],
  //     Seaford: ["11783"],
  //     "South Floral Park": ["11001"],
  //     "Stewart Manor": ["11530"],
  //     Syosset: ["11791"],
  //     Uniondale: ["11553"],
  //     "Upper Brookville": ["11771"],
  //     "Valley Stream": ["11580", "11581"],
  //     Wantagh: ["11793"],
  //     "West Hempstead": ["11552"],
  //     Westbury: ["11590"],
  //     "Williston Park": ["11596"],
  //     Woodbury: ["11797"],
  //     Woodsburgh: ["11598"],
  //   },
  // };

  const cityOptions = useMemo(() => {
    if (!neighborhoodData) return []; // Avoid computation if data is not ready

    return Object.entries(neighborhoodData).flatMap(([county, cities]) =>
      Object.keys(cities).map((cityName) => ({
        value: `${county}-${cityName}`,
        label: cityName,
        group: county,
      }))
    );
  }, [neighborhoodData]);

  const getZipCodeOptions = (selectedCity) => {
    if (!selectedCity) return [];
    const [county, cityName] = selectedCity.split("-");
    const zipCodes = neighborhoodData[county]?.[cityName] || [];
    return zipCodes.map((zip) => ({
      value: zip,
      label: zip,
      group: cityName,
    }));
  };

  const zipCodeOptions = useMemo(() => getZipCodeOptions(city), [city]);
  const shippingZipCodeOptions = useMemo(
    () => getZipCodeOptions(shippingCity),
    [shippingCity]
  );

  const handleCityChange = (newCity) => {
    setCity(newCity);
    setZipCode("");
  };

  const handleShippingCityChange = (newCity) => {
    setShippingCity(newCity);
    setShippingZip("");
  };

  return (
    <div className="bg-white">
      <div className="relative overflow-hidden -mt-1">
        <div className="absolute w-full flex items-center justify-center">
          <img
            src="/assets/checkout.jpeg"
            alt="Checkout"
            className="brightness-50 mt-[-10%] absolute"
          />
        </div>
        <div className="py-6 px-4 md:px-7 text-white relative container font-light">
          <div className="flex items-center gap-2">
            <Link to={"/"}>
              <HomeIcon />
            </Link>
            <FaAngleRight />
            <p>Shopping Cart</p>
            <FaAngleRight />
            <p className="text-yellow-500">Checkout</p>
          </div>
        </div>
      </div>

      {/* info */}
      <div className="container text-3xl mt-5 md:mt-10 my-5 p-4">
        <h3 className="font-medium">Delivery Information</h3>
      </div>
      <div className="container grid grid-cols-1 md:grid-cols-2 gap-10 my-5 p-4 md:px-8">
        <div className={`${infosave ? "hidden" : "block"}`}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-10">
            <div>
              <p>
                Name <span className="text-red-500">*</span>
              </p>
              <input
                type="text"
                placeholder="Your Full Name"
                className="px-5 py-2 rounded outline-none bg-transparent border mt-2 w-full"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {validate && !name && (
                <p className="text-sm text-red-500">This field is required</p>
              )}
            </div>
            <div>
              <p>
                Mobile Number <span className="text-red-500">*</span>
              </p>
              <input
                type="number"
                placeholder="Your Full Name"
                className="px-5 py-2 rounded outline-none bg-transparent border mt-2 w-full"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
              />
              {validate && !number && (
                <p className="text-sm text-red-500">This field is required</p>
              )}
            </div>
          </div>

          {/* delivery address field */}
          <div className="mt-5 md:my-10 border-y py-5">
            <p className="font-medium">
              Billing Address <span className="text-red-500">*</span>
            </p>

            <div>
              <div className=" w-full mt-2">
                <p className="text-sm text-neutral-500">
                  Address Line 1 <span className="text-red-500">*</span>
                </p>
                <input
                  type="text"
                  placeholder="Billing Address line 1"
                  className="px-5 py-2 rounded outline-none bg-transparent border mt-2 w-full"
                  value={billingAddressOne}
                  onChange={(e) => setBillingAddressOne(e.target.value)}
                />
                {validate && !billingAddressOne && (
                  <p className="text-sm text-red-500">This field is required</p>
                )}
              </div>
              <div className=" w-full mt-2">
                <p className="text-sm text-neutral-500">Address Line 1</p>
                <input
                  type="text"
                  placeholder="Billing Address line 2"
                  className="px-5 py-2 rounded outline-none bg-transparent border mt-2 w-full"
                  value={billingAddressTwo}
                  onChange={(e) => setBillingAddressTwo(e.target.value)}
                />
                {/* {validate && !billingAddressTwo && (
                  <p className="text-sm text-red-500">This field is required</p>
                )} */}
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-10">
              <div className="w-full">
                <p>
                  City <span className="text-red-500">*</span>
                </p>
                <input
                  type="text"
                  placeholder="City"
                  className="px-5 py-2 rounded outline-none bg-transparent border mt-2 w-full"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
                {/* <CustomSearchableSelect
                  options={cityOptions}
                  placeholder="Select a city"
                  value={city}
                  setValue={handleCityChange}
                /> */}
                {validate && !city && (
                  <p className="text-sm text-red-500">This field is required</p>
                )}
              </div>

              <div className="w-full">
                <p>
                  Zip Code <span className="text-red-500">*</span>
                </p>
                <input
                  type="number"
                  placeholder="Zip Code"
                  className="px-5 py-2 rounded outline-none bg-transparent border mt-2 w-full"
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value)}
                />
                {/* <CustomSearchableSelect
                  options={zipCodeOptions}
                  placeholder="Select a zip code"
                  value={zipCode}
                  setValue={setZipCode}
                  disabled={!city}
                /> */}
                {validate && !zipCode && (
                  <p className="text-sm text-red-500">This field is required</p>
                )}
              </div>

              {/* State Select */}
              <div className="w-full">
                <p className="">
                  State <span className="text-red-500">*</span>
                </p>
                <Select
                  value={selectedState}
                  defaultValue={selectedState}
                  onValueChange={setSelectedState}
                >
                  <SelectTrigger className="px-5 py-2 rounded outline-none bg-transparent border mt-2 w-full">
                    <SelectValue placeholder="State" />
                  </SelectTrigger>
                  <SelectContent>
                    {usStates?.map((state, i) => (
                      <SelectItem value={state} key={i}>
                        {state}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                {validate && !selectedState && (
                  <p className="text-sm text-red-500">This field is required</p>
                )}
              </div>

              {/* Country Select */}
              <div className="w-full">
                <p className="">
                  Country <span className="text-red-500">*</span>
                </p>
                <Select
                  value={country}
                  defaultValue={country}
                  // onValueChange={setCountry}
                >
                  <SelectTrigger className="px-5 py-2 rounded outline-none bg-transparent border mt-2 w-full">
                    <SelectValue placeholder="Country" />
                  </SelectTrigger>
                  <SelectContent>
                    {countries.map((country, i) => (
                      <SelectItem value={country} key={i}>
                        {country}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                {validate && !country && (
                  <p className="text-sm text-red-500">This field is required</p>
                )}
              </div>
            </div>
          </div>

          {/* delivery address field end */}

          <div className="text-red-400 text-sm">
            Note: Home Delivery is currently limited to Nassua Country and
            Queens.
          </div>

          <p className="font-medium mt-5 md:mt-10 border-t py-5">
            Shipping Address <span className="text-red-500">*</span>
          </p>

          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              className="checkbox"
              id="issame"
              value={isSame}
              onChange={(e) => setIsSame(e.target.checked)}
            />
            <label htmlFor="issame">Same as Billing Address</label>
          </div>

          {/* shipping address field */}
          <div className={` border-b py-5 ${isSame ? "hidden" : "block"}`}>
            <div>
              <div className=" w-full">
                <p className="text-sm text-neutral-500">
                  Address Line 1 <span className="text-red-500">*</span>
                </p>
                <input
                  type="text"
                  placeholder="Shipping Address line 1"
                  className="px-5 py-2 rounded outline-none bg-transparent border mt-2 w-full"
                  value={streetAddressOne}
                  onChange={(e) => setStreetAddressOne(e.target.value)}
                />
              </div>
              <div className=" w-full mt-2">
                <p className="text-sm text-neutral-500">Address Line 1</p>
                <input
                  type="text"
                  placeholder="Shipping Address line 2"
                  className="px-5 py-2 rounded outline-none bg-transparent border mt-2 w-full"
                  value={streetAddressTwo}
                  onChange={(e) => setStreetAddressTwo(e.target.value)}
                />
                {/* {validate && !address && (
                  <p className="text-sm text-red-500">This field is required</p>
                )} */}
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-10">
              <div className="w-full">
                <p>
                  City <span className="text-red-500">*</span>
                </p>
                {/* <input
                  type="text"
                  placeholder="City"
                  className="px-5 py-2 rounded outline-none bg-transparent border mt-2 w-full"
                  value={shippingCity}
                  onChange={(e) => setShippingCity(e.target.value)}
                /> */}
                <CustomSearchableSelect
                  options={cityOptions}
                  placeholder="Select a shipping city"
                  value={shippingCity}
                  setValue={handleShippingCityChange}
                />
              </div>

              <div className="w-full">
                <p>
                  Zip Code <span className="text-red-500">*</span>
                </p>
                {/* <input
                  type="number"
                  placeholder="Zip Code"
                  className="px-5 py-2 rounded outline-none bg-transparent border mt-2 w-full"
                  value={shippingZip}
                  onChange={(e) => setShippingZip(e.target.value)}
                /> */}
                <CustomSearchableSelect
                  options={shippingZipCodeOptions}
                  placeholder="Select a shipping zip code"
                  value={shippingZip}
                  setValue={setShippingZip}
                  disabled={!shippingCity}
                />
              </div>

              {/* State Select */}
              <div className="w-full">
                <p className="">
                  State <span className="text-red-500">*</span>
                </p>
                <Select
                  value={shippingSelectedState}
                  defaultValue={shippingSelectedState}
                  // onValueChange={setShippingSelectedState}
                >
                  <SelectTrigger className="px-5 py-2 rounded outline-none bg-transparent border mt-2 w-full">
                    <SelectValue placeholder="State" />
                  </SelectTrigger>
                  <SelectContent>
                    {usStates?.map((state, i) => (
                      <SelectItem value={state} key={i}>
                        {state}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              {/* Country Select */}
              <div className="w-full">
                <p className="">
                  Country <span className="text-red-500">*</span>
                </p>
                <Select
                  value={shippingCountry}
                  defaultValue={shippingCountry}
                  // onValueChange={setShippingCountry}
                >
                  <SelectTrigger className="px-5 py-2 rounded outline-none bg-transparent border mt-2 w-full">
                    <SelectValue placeholder="Country" />
                  </SelectTrigger>
                  <SelectContent>
                    {countries.map((country, i) => (
                      <SelectItem value={country} key={i}>
                        {country}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </div>
          </div>

          <div className="mt-5 md:mt-10 w-full">
            <div className="mb-3">
              Delivery Method <span className="text-red-500">*</span>
            </div>
            <Select value={deliveryMethod} onValueChange={setDeliveryMethod}>
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="Home delivery" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {/* <SelectLabel>get products from</SelectLabel> */}
                  <SelectItem value="Home Delivery">Home Delivery</SelectItem>
                  <SelectItem value="Store Pickup">Store Pickup</SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
            {validate && !deliveryMethod && (
              <p className="text-sm text-red-500">This field is required</p>
            )}
          </div>

          {deliveryMethod === "Store Pickup" ? (
            <div className="mt-5 md:mt-10 w-full">
              <div className="mb-3">
                Choose Store <span className="text-red-500">*</span>
              </div>
              <RadioGroup
                value={selectedStore}
                onValueChange={setSelectedStore}
              >
                {stores?.map((store, i) => (
                  <div className="flex items-center space-x-2 my-2" key={i}>
                    <RadioGroupItem value={store.name} id={store.name} />
                    <label htmlFor={store.name}>
                      <div>{store.name}</div>
                    </label>
                  </div>
                ))}
              </RadioGroup>

              {validate && !deliveryMethod && (
                <p className="text-sm text-red-500">This field is required</p>
              )}

              <div className="mt-5">
                <p className="font-semibold">Working Hours</p>
                {!storeInfo && (
                  <p className="text-red-400 text-sm mt-2">Select A Store!</p>
                )}
                <div className="mt-2 space-y-3">
                  {storeInfo?.working_hours?.map((item, i) => (
                    <div key={i}>
                      <div>{item.day_of_week}</div>
                      <div>
                        {item.start_time}-{item.end_time}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <div className="mt-5">
              <div className="mb-3">Delivery Type</div>
              <Select value={deliveryType} onValueChange={setDeliveryType}>
                <SelectTrigger className="w-[180px]">
                  <SelectValue placeholder="Standard Delivery" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {/* <SelectLabel>get products from</SelectLabel> */}
                    <SelectItem value="Standard Delivery">
                      Standard Delivery
                    </SelectItem>
                    <SelectItem value="Express Delivery">
                      Express Delivery
                    </SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>

              <div className="mt-5">
                <RadioGroup
                  value={deliverySlot}
                  onValueChange={setDeliverySlot}
                >
                  {delivery_slots?.map((store, i) => (
                    <div className="flex items-center space-x-2 my-2" key={i}>
                      <RadioGroupItem value={store.name} id={store.name} />
                      <label htmlFor={store.name}>
                        <div>{store.day}</div>
                        <div>
                          {store.start_time} - {store.end_time}
                        </div>
                      </label>
                    </div>
                  ))}
                </RadioGroup>
              </div>
            </div>
          )}

          {deliveryMethod === "Store Pickup" && (
            <div className="mt-5 md:mt-10 w-full">
              <div className="my-8">
                <h3 className="text-xl text-black font-medium">
                  Schedule Delivery
                </h3>
                <p className="mt-5 mb-2">Date</p>
                <div class="relative max-w-[270px]">
                  <input
                    aria-label="Date and time"
                    type="datetime-local"
                    class="bg-white border text-black placeholder:text-black px-3 py-2 rounded-lg pl-10 w-full"
                    value={deliveryDate}
                    onChange={(e) => setDeliveryDate(e.target.value)}
                    min={new Date().toISOString().slice(0, 16)}
                  />

                  <FaCalendar className="absolute right-5 top-1/2 transform -translate-y-1/2 text-gray-500 pointer-events-none" />
                </div>
                {validate && !deliveryDate && (
                  <p className="text-sm text-red-500">This field is required</p>
                )}
              </div>
              {/* <p>Order Note (optional)</p>
            <input
              type="Note"
              placeholder="e.g. Special Note for Delivery"
              className="px-5 py-2 rounded outline-none bg-transparent border mt-2 w-full"
              value={note}
              onChange={(e) => setNote(e.target.value)}
            /> */}
            </div>
          )}

          <div className="mt-5 md:mt-10 w-full flex items-center justify-end">
            <button
              onClick={handleSave}
              className="px-5 py-2 rounded-full bg-ourPrimary text-white hover:bg-ourPrimary-foreground transition-all"
            >
              {loader ? " Saving..." : " Save Info"}
            </button>
          </div>
        </div>

        {/* saved info */}
        <div className={`${infosave ? "block" : "hidden"}`}>
          <div className="max-w-[500px] w-full p-6 border rounded-lg bg-white">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold text-gray-800">
                Contact Information
              </h2>
              <span className="px-2 py-1 text-sm font-semibold text-blue-600 bg-blue-100 rounded-full text-nowrap">
                {"Shipping Address"}
              </span>
            </div>
            <div className="flex items-center justify-end">
              <button
                onClick={() => {
                  dispatch(handelInfoSave());
                }}
              >
                <FilePenLine />
              </button>
            </div>
            <div className="space-y-4">
              <div className="flex items-center space-x-3">
                <UserIcon className="w-5 h-5 text-gray-500" />
                <div>
                  <p className="text-sm font-semibold text-gray-600">Name:</p>
                  <p className="text-gray-800">{name}</p>
                </div>
              </div>
              <div className="flex items-center space-x-3">
                <PhoneIcon className="w-5 h-5 text-gray-500" />
                <div>
                  <p className="text-sm font-semibold text-gray-600">Number:</p>
                  <p className="text-gray-800">{number}</p>
                </div>
              </div>
              <div>
                <div className="flex items-start space-x-3">
                  <MapPinIcon className="w-5 h-5 text-gray-500 mt-1" />
                  <div>
                    <p className="text-sm font-semibold text-gray-600">
                      Address:
                    </p>
                    <p className="text-gray-800">
                      {isSame ? billingAddressOne : streetAddressOne}
                    </p>
                  </div>
                </div>
                <div className="flex items-center space-x-3">
                  <div className="w-5"></div>
                  <div>
                    {/* <p className="text-sm font-semibold text-gray-600">City:</p> */}
                    <p className="text-gray-800">
                      {isSame ? zipCode : shippingZip},{" "}
                      {isSame ? city : shippingCity}
                    </p>
                  </div>
                </div>
                <div className="flex items-center space-x-3">
                  <div className="w-5"></div>
                  <div>
                    {/* <p className="text-sm font-semibold text-gray-600">State:</p> */}
                    <p className="text-gray-800">
                      {isSame ? selectedState : shippingSelectedState}
                    </p>
                  </div>
                </div>

                {/* <div className="flex items-center space-x-3">
                  <div className="w-5"></div>
                  <div>
                    <p className="text-sm font-semibold text-gray-600">Zip:</p>
                    <p className="text-gray-800">
                      {isSame ? zipCode : shippingZip}
                    </p>
                  </div>
                </div> */}
                <div className="flex items-center space-x-3">
                  {/* <GlobeIcon className="w-5 h-5 text-gray-500" /> */}
                  <div className="w-5"></div>
                  <div>
                    {/* <p className="text-sm font-semibold text-gray-600">
                    Country:
                  </p> */}
                    <p className="text-gray-800">
                      {isSame ? country : shippingCountry}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex items-center space-x-3">
                <Package className="w-5 h-5 text-gray-500" />
                <div>
                  <p className="text-sm font-semibold text-gray-600">
                    Delivery Method:
                  </p>
                  <p className="text-gray-800">{deliveryMethod}</p>
                </div>
              </div>
              {
                <div className="flex items-center space-x-3">
                  <TimerIcon className="w-5 h-5 text-gray-500" />
                  <div>
                    <p className="text-sm font-semibold text-gray-600">
                      {deliveryMethod == "Home Delivery"
                        ? "Delivery Date"
                        : "Pickup Date & Time"}
                      :
                    </p>
                    <p className="text-gray-800">
                      {deliveryMethod === "Home Delivery"
                        ? deliverySlot &&
                          deliverySlot &&
                          formatString(deliverySlot)
                        : new Date(deliveryDate).toLocaleString("en-US", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                    </p>
                  </div>
                </div>
              }
              {deliveryMethod === "Home Delivery" && (
                <div className="flex items-center space-x-3">
                  <Package className="w-5 h-5 text-gray-500" />
                  <div>
                    <p className="text-sm font-semibold text-gray-600">
                      Delivery Type:
                    </p>
                    <p className="text-gray-800">{deliveryType}</p>
                  </div>
                </div>
              )}
              {storeInfo && (
                <div className="flex items-center space-x-3">
                  <Store className="w-5 h-5 text-gray-500" />
                  <div>
                    <p className="text-sm font-semibold text-gray-600">
                      Store Info:
                    </p>
                    <p className="text-gray-800">{selectedStore}</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="max-w-[450px] w-full mx-auto -mt-2">
          <h2 className="text-2xl font-medium my-2">Order Summery</h2>

          <div className="flex w-full">
            <div className="p-4 border rounded-lg flex flex-col gap-5 w-full">
              {cartItems?.cart?.items?.map((product, i) => (
                <div
                  key={i}
                  className="flex items-center justify-between gap-3 border-b"
                >
                  <div className="size-[100px] flex items-center justify-center">
                    <img
                      src={`${BASE_URL}${product.image}`}
                      alt={product.item_name}
                      className="min-w-[80px]"
                    />
                  </div>
                  <div className="w-full overflow-hidden">
                    <h3 className="truncate">{product.item_name}</h3>
                    <p className="text-sm text-neutral-400">
                      x{product.quantity}
                    </p>
                    <div className="flex items-center gap-2">
                      <p className="font-semibold text-neutral-500">
                        ${product.price}
                      </p>
                      {product.base_price !== product.price && (
                        <p className="text-xs text-neutral-400 line-through">
                          ${product.base_price}
                        </p>
                      )}
                    </div>
                  </div>
                  <div>
                    <h3 className="flex items-center">
                      <FaDollarSign className="text-sm" />
                      {product.amount ?? 0}
                    </h3>
                  </div>
                </div>
              ))}
              <div className="flex items-center justify-between">
                <h4>Subtotal:</h4>
                <p className="flex items-center font-bold">
                  <FaDollarSign />
                  {parseFloat(subTotalPrice?.toFixed(2))}
                </p>
              </div>
            </div>
          </div>

          <div className="mt-5 flex items-center justify-between gap-2 border-b py-3">
            <p className="text-sm">Coupon:</p>
            <div className="flex items-center justify-center gap-2">
              {coupon ? (
                <div>Coupon Code Applied</div>
              ) : (
                <>
                  <input
                    type="text"
                    className="px-5 py-1 rounded-lg bg-transparent border w-[150px] md:w-[200px]"
                    placeholder="Add Coupon Code"
                    value={curpon}
                    onChange={(e) => setCurpon(e.target.value)}
                  />
                  <button
                    onClick={applyCoupon}
                    className="px-5 py-1 rounded-lg border hover:bg-neutral-100 font-semibold outline-none"
                  >
                    Add
                  </button>
                </>
              )}
            </div>
          </div>

          {cartItems?.cart?.taxes?.map((item, i) => (
            <div className="mt-5 flex items-center justify-between">
              <p className="text-sm">{item.tax_type}</p>
              <p className="flex items-center text-neutral-500">
                <FaDollarSign />
                {item.tax_amount}
              </p>
            </div>
          ))}
          <div className="mt-8 flex items-center justify-between border-t pt-2">
            <p className="font-medium">Total:</p>
            <div className="flex items-center gap-2">
              {!curponDiscount ? (
                <p className="flex items-center font-bold">
                  <FaDollarSign />
                  {parseFloat(totalPrice?.toFixed(2)) || 0}
                </p>
              ) : (
                <p className="flex items-center font-bold">
                  <FaDollarSign />
                  {discountedPrice.toFixed()}
                </p>
              )}
            </div>
          </div>

          {infosave && (
            <div>
              {/* <Stripe /> */}

              <div className="mt-5 md:mt-10">
                <button
                  disabled={isLoading}
                  onClick={handlePlaceOrder}
                  style={{ pointerEvents: isLoading ? "none" : "hover" }}
                  className="px-6 py-3 font-semibold bg-ourPrimary hover:bg-ourPrimary-foreground transition-all text-white rounded-full w-full"
                >
                  {isLoading ? "Placing..." : "Place Order"}
                </button>
              </div>

              <div className="mt-5 md:mt-10">
                <h2 className="text-xs font-semibold mb-4">Pay With:</h2>
                <div className="flex items-center justify-center">
                  <div className="flex items-center gap-5">
                    {paymentMethods?.map((item, i) => (
                      <div>
                        <img
                          src={item.imgSrc}
                          alt={item.name}
                          className={item.imgClass}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Checkout;
