import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  get_discountproducts,
  get_hotdeals,
  get_limitedtimeofferproducts,
  get_newproducts,
  get_products_by_category,
  get_riceanddal,
  get_searchproducts,
  get_topsellingproducts,
} from "../../store/reducers/HomeReducer";
import Filter from "./Filter";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/Select";
import ProductDrawer from "./ProductDrawer";
import ProductCard from "../../components/ProductCard";
import SkeletonCarousel from "../../components/SkeletonCarousel";

export default function Component() {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [minPrice, setMinPrice] = useState();
  const [maxPrice, setMaxPrice] = useState();
  const [rate, setRate] = useState();
  const [sortValue, setSortValue] = useState("");
  const [allProducts, setAllProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(20);
  const [isLoading, setIsLoading] = useState(true);
  const [dataFetched, setDataFetched] = useState(false);

  const [searchParams] = useSearchParams();
  const searchValue = searchParams.get("search");
  const specialCategory = searchParams.get("specialCategory");
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const searchCategory = query.get("category");

  const {
    loading,
    hot_deals,
    top_selling_products,
    new_products,
    search_products,
    discount_products,
    all_products,
    riceanddal,
    limited_time_offer_products,
  } = useSelector((state) => state.home);

  const dispatch = useDispatch();

  const sortProducts = (products, sortValue) => {
    if (sortValue === "lowtohigh") {
      return [...products].sort(
        (a, b) => a.price_list_rate - b.price_list_rate
      );
    } else if (sortValue === "hightolow") {
      return [...products].sort(
        (a, b) => b.price_list_rate - a.price_list_rate
      );
    }
    return products;
  };

  const filterByRating = (products, rating) => {
    if (rating) {
      return products.filter((product) => product.rating >= rating);
    }
    return products;
  };

  const filterByPrice = (products, minPrice, maxPrice) => {
    if (minPrice || maxPrice) {
      return products.filter((product) => {
        const price = product.price_list_rate;
        return (
          (!minPrice || price >= minPrice) && (!maxPrice || price <= maxPrice)
        );
      });
    }
    return products;
  };

  useEffect(() => {
    if (searchCategory) {
      setSelectedCategory(searchCategory);
    }
  }, [searchCategory]);

  useEffect(() => {
    setIsLoading(true);
    setDataFetched(false);
    if (searchValue !== "") {
      dispatch(get_searchproducts({ value: searchValue, page, size })).then(
        () => {
          setTimeout(() => {
            setIsLoading(false);
            setDataFetched(true);
          }, 1000);
        }
      );
    }
  }, [searchValue, dispatch, page, size]);

  useEffect(() => {
    if (search_products) {
      setAllProducts(search_products);
    }
  }, [search_products]);

  useEffect(() => {
    setIsLoading(true);
    setDataFetched(false);
    if (searchCategory) {
      dispatch(
        get_products_by_category({ category: searchCategory, page, size })
      ).then(() => {
        setTimeout(() => {
          setIsLoading(false);
          setDataFetched(true);
        }, 1000);
      });
    } else if (selectedCategory) {
      dispatch(
        get_products_by_category({ category: selectedCategory, page, size })
      ).then(() => {
        setTimeout(() => {
          setIsLoading(false);
          setDataFetched(true);
        }, 1000);
      });
    } else {
      dispatch(get_products_by_category({ category: "", page, size })).then(
        () => {
          setTimeout(() => {
            setIsLoading(false);
            setDataFetched(true);
          }, 1000);
        }
      );
    }
  }, [searchCategory, selectedCategory, dispatch, page, size]);

  useEffect(() => {
    if (selectedCategory) {
      setIsLoading(true);
      setDataFetched(false);
      dispatch(
        get_products_by_category({ category: selectedCategory, page, size })
      ).then((response) => {
        setTimeout(() => {
          setAllProducts(response.payload.message.items);
          setIsLoading(false);
          setDataFetched(true);
        }, 1000);
      });
    }
  }, [selectedCategory, dispatch, page, size]);

  useEffect(() => {
    if (
      all_products &&
      !searchValue &&
      !specialCategory &&
      !searchCategory &&
      !selectedCategory
    ) {
      setAllProducts(all_products);
      setTimeout(() => {
        setIsLoading(false);
        setDataFetched(true);
      }, 1000);
    }
  }, [
    all_products,
    searchValue,
    specialCategory,
    searchCategory,
    selectedCategory,
  ]);

  useEffect(() => {
    setIsLoading(true);
    setDataFetched(false);
    if (specialCategory === "Hot Deals") {
      dispatch(get_hotdeals({ page, size })).then(() => {
        setTimeout(() => {
          setIsLoading(false);
          setDataFetched(true);
        }, 1000);
      });
    } else if (specialCategory === "New Products") {
      dispatch(get_newproducts({ page, size })).then(() => {
        setTimeout(() => {
          setIsLoading(false);
          setDataFetched(true);
        }, 1000);
      });
    } else if (specialCategory === "Top Selling") {
      dispatch(get_topsellingproducts({ page, size })).then(() => {
        setTimeout(() => {
          setIsLoading(false);
          setDataFetched(true);
        }, 1000);
      });
    } else if (specialCategory === "Discount Offers") {
      dispatch(get_discountproducts({ page, size })).then(() => {
        setTimeout(() => {
          setIsLoading(false);
          setDataFetched(true);
        }, 1000);
      });
    } else if (specialCategory === "Rice & Dal") {
      dispatch(get_riceanddal({ page, size })).then(() => {
        setTimeout(() => {
          setIsLoading(false);
          setDataFetched(true);
        }, 1000);
      });
    } else if (specialCategory === "Limited Time Offer") {
      dispatch(get_limitedtimeofferproducts({ page, size })).then(() => {
        setTimeout(() => {
          setIsLoading(false);
          setDataFetched(true);
        }, 1000);
      });
    }
    setPage(1);
    setSize(20);
  }, [specialCategory, dispatch, page, size]);

  useEffect(() => {
    if (specialCategory === "Hot Deals") {
      setAllProducts(hot_deals);
    } else if (specialCategory === "New Products") {
      setAllProducts(new_products);
    } else if (specialCategory === "Top Selling") {
      setAllProducts(top_selling_products);
    } else if (specialCategory === "Discount Offers") {
      setAllProducts(discount_products);
    } else if (specialCategory === "Rice & Dal") {
      setAllProducts(riceanddal);
    } else if (specialCategory === "Limited Time Offer") {
      setAllProducts(limited_time_offer_products);
    }
  }, [
    specialCategory,
    hot_deals,
    new_products,
    top_selling_products,
    discount_products,
    riceanddal,
    limited_time_offer_products,
  ]);

  const handleApply = () => {
    let result = [...allProducts];

    if (rate) {
      result = filterByRating(result, rate);
    }

    if (minPrice || maxPrice) {
      result = filterByPrice(result, minPrice, maxPrice);
    }

    if (sortValue) {
      result = sortProducts(result, sortValue);
    }

    setFilteredProducts(result);
  };

  useEffect(() => {
    handleApply();
  }, [rate, minPrice, maxPrice, sortValue, allProducts]);

  return (
    <div className="container p-3 flex gap-5">
      <div className="hidden md:block">
        <Filter
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          minPrice={minPrice}
          maxPrice={maxPrice}
          setMinPrice={setMinPrice}
          setMaxPrice={setMaxPrice}
          rate={rate}
          setRate={setRate}
          handleApply={handleApply}
        />
      </div>

      <div className="w-full">
        <div className="relative">
          <div className="flex items-center justify-between">
            <div className="">
              <div className="block md:hidden sticky top-0">
                <ProductDrawer
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                  minPrice={minPrice}
                  maxPrice={maxPrice}
                  setMinPrice={setMinPrice}
                  setMaxPrice={setMaxPrice}
                  rate={rate}
                  setRate={setRate}
                  handleApply={handleApply}
                />
              </div>
            </div>
            {/* <Select value={sortValue} onValueChange={setSortValue}>
              <SelectTrigger className="w-[160px]">
                <SelectValue placeholder="Sort" />
              </SelectTrigger>
              <SelectContent className='z-[100]'>
                <SelectGroup>
                  <SelectItem value="lowtohigh">Low to High</SelectItem>
                  <SelectItem value="hightolow">High to Low</SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select> */}
            <div className="relative mb-6">
              <div className="relative inline-block">
                <select
                  value={sortValue}
                  onChange={(e) => setSortValue(e.target.value)}
                  className="appearance-none w-[130px] px-4 py-2.5 bg-white border border-gray-200 rounded-lg text-sm text-gray-700 font-medium hover:border-gray-300  cursor-pointer outline-none text-center"
                >
                  <option value="lowtohigh" className="text-center">Low to High</option>
                  <option value="hightolow" className="text-center">High to Low</option>
                </select>
                {/* <ChevronDown className="absolute right-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-500 pointer-events-none" /> */}
              </div>
            </div>
          </div>

          {isLoading || loading || !dataFetched ? (
            <div className="mt-5">
              <SkeletonCarousel full={true} />
            </div>
          ) : filteredProducts.length > 0 ? (
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 p-3">
              {filteredProducts.map((product, i) => (
                <ProductCard product={product} key={i} />
              ))}
            </div>
          ) : allProducts.length > 0 ? (
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 p-3">
              {allProducts.map((product, i) => (
                <ProductCard product={product} key={i} />
              ))}
            </div>
          ) : (
            <h5 className="text-lg font-bold mt-5">No Products to show!</h5>
          )}

          {allProducts.length > 20 && (
            <div className="my-5 flex items-center justify-center">
              <button
                onClick={() => setPage(page + 1)}
                className="px-5 py-2 rounded-lg bg-ourPrimary text-white hover:bg-ourPrimary-foreground transition-all"
              >
                Load More
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
