import React from 'react'

const SkeletonCarousel = ({full}) => {
  return (
    <div className={`grid grid-cols-2 md:grid-cols-3 ${full && "xl:grid-cols-5"} gap-5`}>
      {
        [1,2,3,4,5].slice(0,full ? 5 : 3).map((item,i)=>(
          <div className='h-[400px] bg-slate-200 animate-pulse rounded-lg' key={i}></div>
        ))
      }
    </div>
  )
}

export default SkeletonCarousel
