import { HomeIcon } from "lucide-react";
import React, { useEffect, useState } from "react";
import { FaAngleRight } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import AccountNav from "../../components/Shared/AccountNav/AccountNav";
import NavigationMenu from "./NavigationMenu";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllOrders,
  messageClear,
  re_order,
} from "../../store/reducers/HomeReducer";
import toast from "react-hot-toast";
import { get_cart_quotation } from "../../store/reducers/CartReducer";

const Orders = () => {
  const [curerntShowing, setCurrentShowing] = useState("All");
  const { allOrders, successMessage, errorMessage, loading } = useSelector(
    (state) => state.home
  );
  const [sessionId, setSessionId] = useState(localStorage.getItem('session_id'))
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  const selections = ["All", "To Bill", "To Deliver", "To Receive"];

  useEffect(() => {
    dispatch(getAllOrders({ page, size:20 }));
  }, [page, dispatch]);

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
      dispatch(messageClear());
      dispatch(get_cart_quotation({sessionId}))
      navigate("/");
    }
    if (errorMessage) {
      toast.error(errorMessage);
      dispatch(messageClear());
    }
  }, [successMessage, errorMessage, dispatch]);

  const filteredOrders =
    curerntShowing === "All"
      ? allOrders // Show all orders if "All" is selected
      : allOrders.filter((order) => order.status === curerntShowing);

  //functions
  const handleReorder = async (orderid) => {
    dispatch(re_order({ order_id: orderid }));
  };


  return (
    <div>
      <div className="relative overflow-hidden -mt-1 bg-black">
        <div className="absolute w-full flex items-center justify-center">
          <img
            src="/assets/productbanner.jpeg"
            alt="Checkout"
            className="brightness-50 mt-[10] md:mt-[-50%] absolute border w-full"
          />
        </div>
        <div className="py-6 text-white relative container font-light px-3">
          <div className="flex items-center gap-2">
            <Link to={"/"}>
              <HomeIcon />
            </Link>
            <FaAngleRight />
            <p>Account</p>
            <FaAngleRight />
            <p className="text-yellow-500">My Orders</p>
          </div>
        </div>
      </div>

      <div className="container flex items-start gap-10 h-full w-full bg-white  md:px-8">
        <div className="hidden lg:block max-w-[300px] w-full">
          <AccountNav />
        </div>

        <div className="rounded-lg w-full my-5">
          <div className="p-4 block md:hidden sticky top-0 bg-white z-20">
            <div className="border px-2 py-3 rounded-lg flex items-center justify-between">
              <h3 className="font-semibold">Manage My Account</h3>
              <NavigationMenu />
            </div>
          </div>

          <div className="border-b">
            <div className="flex max-w-[600px] w-full items-center justify-between px-3 font-semibold">
              {selections?.map((item, i) => (
                <button
                  key={i}
                  onClick={() => setCurrentShowing(item)}
                  className={`${
                    curerntShowing === item
                      ? "border-b-2 border-b-ourPrimary"
                      : "border-b-transparent"
                  } py-1 px-2 cursor-pointer border-b-2 `}
                >
                  {item}
                </button>
              ))}
            </div>
          </div>

          {/* orders */}
          <div className="mt-6 flex flex-col px-3 gap-5">
            {filteredOrders?.map((order, i) => (
              <div key={i} className="w-full border rounded">
                <div className="flex items-center flex-col md:flex-row">
                  <div className="flex items-center w-full">
                    <div className="flex flex-col md:flex-row items-center justify-between w-full h-full px-5 py-3">
                      <div className="flex flex-col gap-1">
                        <h3 className="font-bold text-neutral-700">
                          {order.order_id}
                        </h3>
                        <p className="font-semibold">Date: {order.date}</p>
                        <p className="font-semibold">Status: {order.status}</p>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-start md:items-center justify-between h-full gap-5 px-4 w-full md:w-auto my-5">
                    <h4 className="text-xl font-semibold text-neutral-700">
                      $ {order.total_amount}
                    </h4>
                    <button
                      onClick={() => handleReorder(order.order_id)}
                      className="px-10 py-2 rounded-lg border border-green-700 font-semibold text-green-700 hover:bg-green-700 hover:text-white transition-all"
                    >
                      Reorder
                    </button>
                  </div>
                </div>
                <Link
                  to={`/orderdetails/${order.order_id}`}
                  className="w-fill flex items-center justify-between py-3 px-8 border-t text-neutral-500 font-semibold"
                >
                  <div>
                    <h3>
                      <span className="font-bold">View Details</span>{" "}
                      <span>(Order #{order.order_id})</span>
                    </h3>
                  </div>
                  <div>
                    <FaAngleRight className="text-xl text-green-700" />
                  </div>
                </Link>
              </div>
            ))}

            {loading && (
              <div className="h-[160px] w-full rounded-lg bg-slate-200 animate-pulse"></div>
            )}

            {!loading && filteredOrders?.length === 0 && (
              <div>No Product To Show!</div>
            )}

            {
              <div className="flex items-center justify-center my-10 gap-5">
                <button
                  onClick={() => {
                    page !== 1 && setPage(page - 1);
                  }}
                  className="bg-ourPrimary hover:bg-ourPrimary-foreground text-white transition-all px-5 py-2 rounded-lg"
                >
                  Prev
                </button>
                {page}
                <button
                  onClick={() => setPage(page + 1)}
                  className="bg-ourPrimary hover:bg-ourPrimary-foreground text-white transition-all px-5 py-2 rounded-lg"
                >
                  Next
                </button>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Orders;
