import React, { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import DiscountCard from "./DiscountCard";
import { useDispatch, useSelector } from "react-redux";
import {
  get_beverage_offer,
  get_deal_of_the_month,
  get_flash_sales,
  get_natural_spices_offer,
  get_special_discount,
  get_summer_sale,
} from "../../store/reducers/HomeReducer";

const DiscountStacking = () => {
  const {
    flash_sale,
    special_discount,
    summer_sale,
    beverage_offer,
    natural_spices_offer,
    deal_of_the_month,
    loading,
  } = useSelector((state) => state.home);

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const discount = searchParams.get("offer");

  useEffect(() => {
    dispatch(get_flash_sales({page:1, size:100}));
    dispatch(get_special_discount({page:1, size:100}));
    dispatch(get_summer_sale({page:1, size:100}));
    dispatch(get_beverage_offer({page:1, size:100}));
    dispatch(get_natural_spices_offer({page:1, size:100}));
    dispatch(get_deal_of_the_month({page:1, size:100}));
  }, [dispatch]);


  const flashsaleRef = useRef(null);
  const summerSaleRef = useRef(null);
  const offerSaleRef = useRef(null);
  const bestDealsRef = useRef(null);
  const NaturalSpices = useRef(null);
  const BevarageOffer = useRef(null);

  const headerHeight = 80;

  useEffect(() => {
    const elementRefs = {
      flashsale: flashsaleRef,
      summersale: summerSaleRef,
      offersale: offerSaleRef,
      bestdeals: bestDealsRef,
      naturalspices: NaturalSpices,
      bevarageoffer: BevarageOffer,
    };

    const targetRef = elementRefs[discount]?.current;
    if (targetRef) {
      window.scrollTo({
        top: targetRef.offsetTop - headerHeight,
        behavior: "smooth",
      });
    }
  }, [discount]);

  return (
    <div className="container p-3">
      <div className="flex flex-col gap-10">
        <div id="offersale" ref={offerSaleRef}>
          <DiscountCard title={"Offer Sale"} products={special_discount} />
          {special_discount.length === 0 && <h2 className="font-semibold my-5">No Products to Show!</h2>}
        </div>
        <div id="bestdeals" ref={bestDealsRef}>
          <DiscountCard title={"BEST DEALS OF THE WEEK"} products={deal_of_the_month} />
          {deal_of_the_month.length === 0 && <h2 className="font-semibold my-5">No Products to Show!</h2>}
        </div>
        <div id="bestdeals" ref={NaturalSpices}>
          <DiscountCard title={"Natural Spices"} products={natural_spices_offer} />
          {natural_spices_offer.length === 0 && <h2 className="font-semibold my-5">No Products to Show!</h2>}
        </div>
        <div id="flashsale" ref={flashsaleRef}>
          <DiscountCard title={"FLASH SALE"} products={flash_sale} />
          {flash_sale.length === 0 && <h2 className="font-semibold my-5">No Products to Show!</h2>}
        </div>
        <div id="bevarageoffer" ref={BevarageOffer}>
          <DiscountCard title={"Beverage Offer's"} products={beverage_offer} />
          {beverage_offer.length === 0 && <h2 className="font-semibold my-5">No Products to Show!</h2>}
        </div>
        <div id="summersale" ref={summerSaleRef}>
          <DiscountCard title={"Summer Sale"} products={summer_sale} />
          {summer_sale.length === 0 && <h2 className="font-semibold my-5">No Products to Show!</h2>}
        </div>
      </div>
    </div>
  );
};

export default DiscountStacking;
