import { useState, useEffect } from 'react'
import { CheckCircleIcon, ArrowRightIcon } from 'lucide-react'
import { Link, useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { get_cart_quotation } from '../store/reducers/CartReducer'

export default function PaymentSuccess() {
  const [mounted, setMounted] = useState(false)
  const [searchParams] = useSearchParams()
  const [sessionId, setSessionId] = useState(
    localStorage.getItem("session_id")
  );
  const amount = searchParams.get('amount')
  const status = searchParams.get('redirect_status')

  const dispatch = useDispatch()

  useEffect(() => {
    setMounted(true)
  }, [])

  useEffect(()=> {
    dispatch(get_cart_quotation({session_id: sessionId}))
  },[dispatch, sessionId])

  return (
    <div className="bg-gradient-to-br from-green-50 to-blue-50 flex items-center justify-center p-4">
      <div className={`max-w-md my-10 w-full bg-white rounded-2xl shadow-xl p-8 transform transition-all duration-500 ${mounted ? 'scale-100 opacity-100' : 'scale-95 opacity-0'}`}>
        <div className="text-center">
          <CheckCircleIcon className="mx-auto h-24 w-24 text-green-500 mb-6 animate-bounce" />
          <h1 className="text-3xl font-bold text-gray-900 mb-4">Payment Successful!</h1>
          <p className="text-lg text-gray-600 mb-8">Thank you for your purchase. Your transaction has been completed successfully.</p>
        </div>
        
        <Link to={'/'} className="w-full bg-gradient-to-r from-green-400 to-blue-500 hover:from-green-500 hover:to-blue-600 text-white font-bold py-3 px-4 rounded-lg transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 flex items-center justify-center">
          <span>Continue Shopping</span>
          <ArrowRightIcon className="ml-2 h-5 w-5" />
        </Link>
      </div>
    </div>
  )
}