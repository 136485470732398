import React from "react";
import { Outlet } from "react-router-dom";
import NavBar from "../components/Shared/NavBar/NavBar";
import Footer from "../components/Shared/Footer/Footer";
import ScrollTop from "../components/ScrollTop";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import store from "../store";

const MainLayout = () => {
  return (
    <Provider store={store}>
      <div className="flex flex-col h-screen">
        <ScrollTop />
        <NavBar />
        <Toaster
          position="top-center"
          reverseOrder={false}
          gutter={1}
          containerClassName=""
          containerStyle={{}}
        />
        <main className="flex-grow bg-white">
          <Outlet />
        </main>
        <Footer />
      </div>
    </Provider>
  );
};

export default MainLayout;
