import { MoveRight } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { get_category } from "../../store/reducers/HomeReducer";

const BASE_URL = process.env.REACT_APP_API_URL;

const ShopByCategory = () => {
  const { categories } = useSelector((state) => state.home);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true); // Local loading state

  useEffect(() => {
    const fetchCategories = async () => {
      await dispatch(get_category());
      setLoading(false); // Set loading to false after categories are fetched
    };

    fetchCategories();
  }, [dispatch]);


  return (
    <div className="container mx-auto py-8 px-3">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg md:text-2xl font-semibold">Shop by Category</h2>
        <Link
          to={`/products`}
          className="text-green-600 flex items-center hover:text-green-700 transition-all text-[14px] md:text-[18px] font-normal"
        >
          View All{" "}
          <span className="ml-1">
            <MoveRight />
          </span>
        </Link>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-5 mt-10">
        {loading ? (
          [1, 2, 3, 4, 5].map((item, i) => (
            <div
              key={i}
              className="h-52 bg-slate-300 rounded-lg animate-pulse"
            ></div>
          ))
        ) : categories.length > 0 ? (
          categories.slice(0, 10).map((category) => (
            <Link
              to={`/products?category=${encodeURIComponent(
                category.name.trim()
              )}`}
              key={category.name}
              className="p-3 border border-[#329AC6] rounded-2xl hover:shadow-lg hover:shadow-ourPrimary/30 bg-gradient-to-t from-[#329ac659] via-transparent to-transparent hover:border-ourPrimary hover:bg-gradient-to-t hover:from-[#fff4ca] hover:via-transparent hover:to-transparent transition-all"
            >
              <img
                src={`${category?.image ? `${BASE_URL}${category.image}` : "/assets/category.png"}`}
                alt={category.name}
                className="object-cover mb-2 mix-blend-multiply"
              />
              <h3 className="text-center text-lg font-medium">
                {category.name}
              </h3>
            </Link>
          ))
        ) : (
          <div>
            <h1 className="text-lg font-bold my-10">No Categories to Show!</h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default ShopByCategory;
